import React, { useEffect, useState } from "react";
import { InputMask } from 'react-input-mask'
import { formatCnpj, formatCpf, formatRg } from "../utils/Tools";
import axios from "axios";

const DevedorSolidario = ({ updateFormData }) => {
    const emissor = JSON.parse(localStorage.getItem('emissor'));
    const [tipo, setTipo] = useState("PF"); // Inicialmente, define como pessoa física (PF)
    const [solidarioName, setSolidarioName] = useState(emissor ? emissor.devedorSolidario.solidarioName : "");
    const [solidarioOcupacao, setSolidarioOcupacao] = useState(emissor ? emissor.devedorSolidario.solidarioOcupacao : "");
    const [solidarioCep, setSolidarioCep] = useState(emissor ? emissor.devedorSolidario.solidarioCep : "");
    const [solidarioRua, setSolidarioRua] = useState(emissor ? emissor.devedorSolidario.solidarioRua : "");
    const [solidarioCidade, setSolidarioCidade] = useState(emissor ? emissor.devedorSolidario.solidarioCidade : "");
    const [solidarioEstado, setSolidarioEstado] = useState(emissor ? emissor.devedorSolidario.solidarioEstado : "");
    const [solidarioNumero, setSolidarioNumero] = useState(emissor ? emissor.devedorSolidario.solidarioNumero : "");
    const [solidarioComplemento, setSolidarioComplemento] = useState(emissor ? emissor.devedorSolidario.solidarioComplemento : "");
    const [solidarioCpf, setSolidarioCpf] = useState(emissor ? emissor.devedorSolidario.solidarioCpf : "");
    const [solidarioCnpj, setSolidarioCnpj] = useState(emissor ? emissor.devedorSolidario.solidarioCnpj : "");
    const [solidarioEmail, setSolidarioEmail] = useState(emissor ? emissor.devedorSolidario.solidarioEmail : "");
    const [isLoading, setIsLoading] = useState(false);

    const handleSearch2 = async (cep) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
            const data = response.data;

            setSolidarioRua(data.logradouro || "");
            setSolidarioNumero("");
            setSolidarioComplemento("");
            setSolidarioCidade(data.localidade || "");
            setSolidarioEstado(data.uf || "");
        } catch (error) {
            console.error('Erro ao buscar endereço:', error);
        } finally {
            setIsLoading(false);
        }
    };
    const handleCepChange2 = (e) => {
        const newCep = e.target.value.replace(/\D/g, "");
        setSolidarioCep(newCep);

        if (newCep.length === 8) {
            handleSearch2(newCep);
        }
    };
    const toggleTipo = () => {
        setTipo(tipo === "PF" ? "PJ" : "PF");
    };

    useEffect(() => {
        // Create a formDataObject with all the form field values
        const formDataObject = {
            solidarioName,
            solidarioOcupacao,
            solidarioCep,
            solidarioRua,
            solidarioCidade,
            solidarioEstado,
            solidarioNumero,
            solidarioComplemento,
            solidarioCpf,
            solidarioCnpj,
            solidarioEmail,
        };

        // Call the updateFormData function from props to update the form data in the parent component
        updateFormData(formDataObject);
    }, [tipo, solidarioName, solidarioOcupacao, solidarioCep, solidarioRua, solidarioCidade, solidarioEstado, solidarioNumero, solidarioComplemento, solidarioCpf, solidarioCnpj, solidarioEmail, isLoading]);

    return (
        <form className="formDoc1">
            <div className="divTitleDoc">
                <h1 className="h1Doc">DEVEDOR SOLIDÁRIO</h1>
            </div>
            <div type="button" style={{
                width: '100%',
                padding: '10px',
                cursor: 'pointer',
                backgroundColor: '#2C3E50',
                color: 'white',
                textAlign: 'center',
                borderRadius: '5px',
                marginInline: '40px',
                marginBottom: '10px'
            }} onClick={toggleTipo}>
                {tipo === "PF" ? "Pessoa Física (CPF)" : "Pessoa Jurídica (CNPJ)"}
            </div>

            {tipo === "PF" ? (
                <div className="divInputsDoc">
                    <label htmlFor="" className="labelInputsDoc">
                        Nome:
                    </label>
                    <input
                        type="text"
                        className={`inputsDoc`}
                        value={solidarioName}
                        onChange={(e) => { setSolidarioName(e.target.value) }}
                    />
                </div>
            ) : (
                <div className="divInputsDoc">
                    <label htmlFor="" className="labelInputsDoc">
                        Razão Social:
                    </label>
                    <input
                        type="text"
                        className={`inputsDoc`}
                        value={solidarioName} // Você precisa criar o estado fCnpj
                        onChange={(e) => { setSolidarioName(e.target.value) }}
                    />
                </div>
            )}

            <div></div>
            <div className="divInputsDoc">
                <label htmlFor="" className="labelInputsDoc">
                    Ocupação:
                </label>
                <input
                    type="text"
                    className={`inputsDoc`}
                    value={solidarioOcupacao}
                    onChange={(e) => { setSolidarioOcupacao(e.target.value) }}
                    maxLength={40} />
            </div>
            <div className="divInputsDoc">
                <label htmlFor="" className="labelInputsDoc">
                    CEP:
                </label>
                <input

                    type="text"
                    className={`inputsDoc`}
                    value={solidarioCep}
                    onChange={(e) => { handleCepChange2(e) }}
                />
            </div>
            {isLoading ? (
                <p className="loading">Carregando...</p>
            ) : (
                <div className="divFull">
                    <div className="rowDiv">
                        <div className="divInputsDoc">
                            <label htmlFor="" className="labelInputsDoc">
                                Rua:
                            </label>
                            <input
                                type="text"
                                className={`inputsDoc`}
                                value={solidarioRua}
                                onChange={(e) => setSolidarioRua(e.target.value)}
                                maxLength={40}
                            />
                        </div>
                        <div className="divInputsDoc">
                            <label htmlFor="" className="labelInputsDoc">
                                Número:
                            </label>
                            <input
                                type="text"
                                className={`inputsDoc`}
                                value={solidarioNumero}
                                onChange={(e) => setSolidarioNumero(e.target.value)}
                                maxLength={40}
                            />
                        </div>
                        <div className="divInputsDoc">
                            <label htmlFor="" className="labelInputsDoc">
                                Complemento:
                            </label>
                            <input
                                type="text"
                                className={`inputsDoc`}
                                value={solidarioComplemento}
                                onChange={(e) => setSolidarioComplemento(e.target.value)}
                                maxLength={40}
                            />

                        </div>
                    </div>


                </div>
            )}
            <div className="divInputsDoc">
                <label htmlFor="" className="labelInputsDoc">
                    Cidade:
                </label>
                <input
                    type="text"
                    className={`inputsDoc`}
                    value={solidarioCidade}
                    onChange={(e) => setSolidarioCidade(e.target.value)}
                    maxLength={40}
                />
            </div>
            <div className="divInputsDoc">
                <label htmlFor="" className="labelInputsDoc">
                    Estado:
                </label>
                <input
                    type="text"
                    className={`inputsDoc`}
                    value={solidarioEstado}
                    onChange={(e) => setSolidarioEstado(e.target.value)}
                    maxLength={40}
                />
            </div>
            {tipo === "PF" ? (
                <div className="divInputsDoc">
                    <label htmlFor="" className="labelInputsDoc">
                        CPF:
                    </label>
                    <input
                        type="text"
                        className={`inputsDoc`}
                        value={formatCpf(solidarioCpf)}
                        onChange={(e) => { setSolidarioCpf(e.target.value) }}
                        maxLength={14}
                    />
                </div>
            ) : (
                <div className="divInputsDoc">
                    <label htmlFor="" className="labelInputsDoc">
                        CNPJ:
                    </label>
                    <input
                        type="text"
                        className={`inputsDoc`}
                        value={formatCnpj(solidarioCnpj)} // Você precisa criar o estado fCnpj
                        onChange={(e) => { setSolidarioCnpj(e.target.value) }}
                        maxLength={18}
                    />
                </div>
            )}
            <div className="divInputsDoc">
                <label htmlFor="" className="labelInputsDoc">
                    e-mail:
                </label>
                <input
                    type="text"
                    className={`inputsDoc`}
                    value={solidarioEmail}
                    onChange={(e) => { setSolidarioEmail(e.target.value) }}
                    maxLength={40} />
            </div>
        </form>
    );
};

export default DevedorSolidario;
