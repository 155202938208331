import React, { useEffect, useState } from "react";
import axios from "axios";
import '../styles/PostNote.css'
import { IoIosCloudUpload } from 'react-icons/io';
import { formatFileSize } from "../utils/Tools";
import { FaSpinner } from "react-icons/fa";

const LaqusNotes = ({
    enable=false,
    emitNote,
    buttonDisable=false
}) => {
    const expectedDocumentCount = 2; 
    const [documentFields, setDocumentFields] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const setUploadFile = ( { fileType, file } ) =>{
        let substituido = false;
        const uploadedFilesUpdate = uploadedFiles.map(( uploadedFile =>{
            if(uploadedFile.fileType == fileType){
                substituido= true;
                uploadedFile = { fileType, file };
            }
            return uploadedFile;
        }));
        if(!substituido){
            setUploadedFiles([...uploadedFiles, { fileType, file }]);
        }else{
            setUploadedFiles([...uploadedFilesUpdate]);
        }
    }

    const handleRemoveFile = (index) => {
        const updatedFiles = [...uploadedFiles];
        updatedFiles.splice(index, 1);
        setUploadedFiles(updatedFiles);
    };
    const handleFileChange = (e, fileType) => {
        const selectedFiles = e.target.files;
        if (selectedFiles.length === 1) {
            const file = selectedFiles[0];
            setUploadFile({ fileType, file });
            console.log("uploadedFiles",uploadedFiles);
            console.log("file",file);
        };
    };

    useEffect(() => {
        // Verifique se todos os documentos foram enviados para habilitar o botão `handleSubmit`
        if (uploadedFiles.length == expectedDocumentCount) {
            setIsSubmitDisabled(false);
        } else {
            setIsSubmitDisabled(true);
        }
        console.log()
    }, [uploadedFiles, documentFields]);

    const emitNoteFile = async () =>{
        /** Libera campos de upload */
        const onSuccess = () =>{
            setUploadedFiles([]);
        }
        await emitNote({files:uploadedFiles, onSuccess});
    }

    return (<>
        {enable? (
            <div className="rowDivs1">
            <div className="uploadForm ">
            <div className="uploadedFilesList">
                {uploadedFiles.map((uploadedFile, index) => (
                    <div key={index} className="uploadedFileItem">
                        <span>{uploadedFile.fileType}</span>
                        <span>{uploadedFile.file.name}</span>
                        <span>{formatFileSize(uploadedFile.file.size)}</span>
                        {loading || buttonDisable ? (
                            <button className="button11" >
                                <FaSpinner className="uploadIcon" spin />
                            </button>
                        ) : (
                            <button className="button11" onClick={() => handleRemoveFile(index)}>
                                Remover
                            </button>
                        )}
                    </div>
                ))}
            </div>
            <label className="customFileInput">
                <input type="file" onChange={(e) => { handleFileChange(e, 'boletim') }} style={{ display: "none" }} disabled={loading || buttonDisable} />
                {loading || buttonDisable ? (
                    <FaSpinner className="uploadIcon" spin />
                ) : (
                    <IoIosCloudUpload className="uploadIcon" />
                )}
                <span className="uploadText" style={{ textAlign: 'center', width: "100%" }}>
                    {loading ||buttonDisable ? 
                    "Enviando..." : 
                    "ANEXAR BOLETIM DE SUBSCRIÇÃO ASSINADO"}
                </span>
            </label>
            <label className="customFileInput">
                <input type="file" onChange={(e) => { handleFileChange(e, 'constitutivo') }} style={{ display: "none" }} disabled={loading || buttonDisable} />
                {loading || buttonDisable ? (
                    <FaSpinner className="uploadIcon" spin />
                ) : (
                    <IoIosCloudUpload className="uploadIcon" />
                )}
                <span className="uploadText" style={{ textAlign: 'center', width: "100%" }}>
                {loading ||buttonDisable ? 
                    "Enviando..." : 
                    "ANEXAR TERMO CONSTITUTIVO ASSINADO"}
                </span>
            </label>
            <div style={{ width: "100%", marginInline: '20%', height: '5vh', marginBottom: '5vh' }}>
                <button 
                    onClick={emitNoteFile}
                    disabled={isSubmitDisabled||buttonDisable} // Usando o estado isSubmitDisabled para habilitar/desabilitar o botão e buttonDisable para disabilitar durante a escrituracao
                    style={{
                        width: "100%",
                        backgroundColor: isSubmitDisabled ? "gray" : "#013872",
                    }}
                >
                    SOLICITAR ESCRITURAÇÃO LAQUS
                </button>
            </div>
            </div>
        </div>
        ):""}
        
    </>
    )
}

export default LaqusNotes;