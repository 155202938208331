import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;

const notaLaqus = async  ({nota}) => {
    const url = `${baseUrl}/laqus/nota`;
    const headers = {
        'Content-Type': 'application/json',
    };
    
   return await axios.post(url, nota , { headers: headers });
}

const escrituracaoNotaLaqus = async  ({userRef, laqusId}) => {
    const url = `${baseUrl}/laqus/nota-escriturar`;
    const headers = {
        'Content-Type': 'application/json',
    };
    
   return await axios.post(url, {userRef, laqusId} , { headers: headers });
}

const  notaLaqusUpload  = async ({userRef, laqusId, files}) => {
    const url = `${baseUrl}/laqus/nota-documentos`;
    const headers = {
        'Content-Type': 'multipart/form-data',
    };
    const formData = new FormData();
    formData.append("laqusId", laqusId);
    formData.append("userRef", userRef);
    files.map((data)=>{
        console.log({
            fileType:data.fileType, 
            file: data.file
        });
        formData.append(
            data.fileType?.trim(), 
            data.file
        );
        return data;
    });
    return await axios.post(url, formData , { headers: headers });
}

const regularizaEmissor = async (
    {
        userRef, 
        emissor,
        emissorId, 
        emissorCnpj,
    }
) => {
    const dados = {
        userRef, 
        emissor,
        emissorId, 
        emissorCnpj,
    };
    const url = `${baseUrl}/laqus/regulariza-cadastro-emissor`;
    const headers = {
        'Content-Type': 'application/json',
    };
    
    return await axios.post(url, JSON.stringify(dados), { headers: headers });
}

const regularizaInvestidor = async (
    {userRef, investidor, investidorCnpj,  investidorId }
) => {
    const dados = {
        userRef,
        investidor,
        investidorCnpj,
        investidorId
    };
    const url = `${baseUrl}/laqus/regulariza-cadastro-investidor`;
    const headers = {
        'Content-Type': 'application/json',
    };
    
     return await axios.post(url, JSON.stringify(dados), { headers: headers });
}

export  {
    notaLaqus,
    notaLaqusUpload,
    escrituracaoNotaLaqus,
    regularizaEmissor,
    regularizaInvestidor,
};
