import axios from "axios";
import { FaPlus } from "react-icons/fa6";
import { useEffect, useState } from "react";
import Header from "./Header";
import "../styles/Emissor.css";
import ConvertApi from 'convertapi-js'
let convertApi = ConvertApi.auth('secret_69SIAEgJFNBWhRNC');
let params = convertApi.createParams()

const EnviarArquivos = () => {
    const [documentFields, setDocumentFields] = useState([]);
    const [mainDocuments, setMainDocuments] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const [img, setImg] = useState([]);
    const [loading, setLoading] = useState(false);
    // Função para adicionar um novo campo de documento dos sócios
    const addDocumentField = () => {
        setDocumentFields([...documentFields, ""]);
    };

    const handleExpand = () => {
        setIsExpanded(!isExpanded);
    };
    useEffect(() => {
        handleExpand()

    }, [])

    // Função para remover um campo de documento dos sócios pelo índice
    const removeDocumentField = (index) => {
        const updatedFields = [...documentFields];
        updatedFields.splice(index, 1);
        setDocumentFields(updatedFields);
    };
    const updateDocumentFieldValue = (index, value) => {
        const updatedFields = [...documentFields];
        updatedFields[index] = value;
        setDocumentFields(updatedFields);
    };


    const handleFileChange = (e) => {
        const selectedFiles = e.target.files;
        if (selectedFiles.length === 1) {
            const updatedMainDocuments = [...mainDocuments, selectedFiles[0]];
            setMainDocuments(updatedMainDocuments);
        }
    };

    return (
        <body className="homeBody">
            <div className="fixed">
                <Header selected="upload"></Header>
            </div>
            <div className="mainDoc2">
                <form className="formDoc1">
                    <div className="divTitleDoc">
                        <h3 className="h1Doc1">Anexar Documentos</h3>
                    </div>
                    <div className="divInputsDoc1">
                        <label htmlFor="" className="labelInputsDoc1">
                            Relatório de Faturamento (PDF)
                        </label>
                        <input
                            type="file"
                            name="files"
                            className={`file-input ${isExpanded ? 'expandedDoc' : 'collapsed'}`}
                            accept=".pdf, .doc, .docx"
                            onChange={(e) => handleFileChange(e)}
                        />
                    </div>
                    <div className="divInputsDoc1">
                        <label htmlFor="" className="labelInputsDoc1">
                            Contrato Social/Ata de Constituição (PDF)
                        </label>
                        <input
                            type="file"
                            name="files"
                            className={`file-input ${isExpanded ? 'expandedDoc' : 'collapsed'}`}
                            accept=".pdf, .doc, .docx"
                            onChange={(e) => handleFileChange(e)}
                        />
                    </div>
                    <div className="divInputsDoc1">
                        <label htmlFor="" className="labelInputsDoc1">
                            Documentos de Garantia (PDF)
                        </label>
                        <input
                            type="file"
                            name="files"
                            className={`file-input ${isExpanded ? 'expandedDoc' : 'collapsed'}`}
                            accept=".pdf, .doc, .docx"
                            onChange={(e) => handleFileChange(e)}
                        />
                    </div>
                    {documentFields.map((field, index) => (
                        <div key={index} className="divInputsDoc1">
                            <label htmlFor="" className="labelInputsDoc1">
                                Documentos do {index + 1}º Sócio(a) (PDF)
                            </label>
                            <input
                                type="file"
                                name="files"
                                className={`file-input ${isExpanded ? 'expandedDoc' : 'collapsed'}`}
                                accept=".pdf, .doc, .docx"
                                onChange={(e) => handleFileChange(e)}
                            />
                            <button onClick={() => removeDocumentField(index)}>Remover</button>
                        </div>
                    ))}



                    {loading ?
                        <div className="p">Inserindo Arquivos Na Minuta</div>
                        :
                        null
                    }
                    <div className="div12">
                        <div className="addButton1" onClick={addDocumentField}>
                            <FaPlus />Adicionar Documento dos Sócios
                        </div>
                        <div className="addButton1">
                            <div className="button2" >
                                Enviar Arquivos
                            </div>
                        </div>
                    </div>
                </form>
            </div>

        </body>
    )
}

export default EnviarArquivos